import { FC } from 'react';
import Badge from '../../../shared/badge/Badge';
import { useTranslation } from 'react-i18next';

const DataImportOverview: FC = () => {
  const { t } = useTranslation(['table-view']);

  return (
    <div className="flex flex-wrap items-center gap-2">
      <div>
        <Badge textClass="text-dpm-gray-2 !font-normal" text={t('filters.import.tag')} />
      </div>
    </div>
  );
};

export default DataImportOverview;
